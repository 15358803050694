import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  children: ReactNode;
};

export function Layout({ className = "", children }: Props) {
  return (
    <div className={twMerge("flex flex-col min-h-screen", className)}>
      {children}
    </div>
  );
}
