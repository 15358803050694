import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  children?: ReactNode;
  className?: string;
};

export function Main({ children, className }: Props) {
  return (
    <div
      className={twMerge(
        "container flex flex-col flex-grow gap-8 lg:gap-16 px-4 py-8 mx-auto lg:max-w-screen-lg",
        className
      )}
    >
      {children}
    </div>
  );
}
