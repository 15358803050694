import { ComponentProps, ReactNode } from "react";
import { Link } from "@remix-run/react";
import { twMerge } from "tailwind-merge";

type Variant =
  | "primary"
  | "secondary"
  | "link"
  | "light"
  | "dark"
  | "outline"
  | "default";

type Props = ComponentProps<typeof Link> & {
  to: string;
  children: ReactNode;
  variant?: Variant;
  disabled?: boolean;
  className?: string;
};

export function Anchor({
  to,
  children,
  variant = "default",
  disabled = false,
  className,
  ...props
}: Props) {
  switch (variant) {
    case "primary":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-neutral-300",
        "bg-primary-background text-primary-text hover:bg-primary-background/90",
        className
      );

      break;
    case "secondary":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-neutral-300",
        "bg-secondary-background text-secondary-text hover:bg-secondary-background/90",
        className
      );

      break;
    case "light":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-neutral-300",
        "bg-neutral-200 text-neutral-900 hover:bg-neutral-200/80",
        className
      );

      break;
    case "dark":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-neutral-300",
        "bg-neutral-800 text-white hover:bg-neutral-800/90",
        className
      );

      break;
    case "outline":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-neutral-300",
        "border-primary-background text-primary-background hover:bg-primary-background hover:border-primary-background hover:text-primary-text",
        className
      );

      break;
    case "default":
      className = twMerge(
        "inline-flex items-center justify-center px-4 py-2 font-medium border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-neutral-300",
        "bg-white border-neutral-200 text-neutral-900 hover:bg-neutral-50",
        className
      );

      break;
    case "link":
      className = twMerge(
        "font-medium focus:underline hover:underline underline-offset-4",
        "text-blue-600 hover:text-blue-500",
        className
      );

      break;
  }

  if (disabled) {
    return (
      <button className={twMerge(className, "opacity-75")}>{children}</button>
    );
  }

  if (to.startsWith("http")) {
    return (
      <a href={to} className={twMerge(className)} {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={twMerge(className)} {...props}>
      {children}
    </Link>
  );
}
