import { useRouteLoaderData } from "@remix-run/react";

type Result = {
  id: string;
  name: string;
  email: string;
};

export function useUser(): Result | null {
  const data = useRouteLoaderData<{ user: Result }>("root");

  return data?.user ?? null;
}
